
.playercard {
    width: 80%;
    height: 100%;
    backdrop-filter: blur(20px);

    background-color: rgba(15, 15, 15, 0.384);
    border-radius: 20px;

    .card {
        border-width: .15rem;
        border-style: solid;
        border-color: rgba(78, 78, 78, 0.377);
        background-color: rgba(15, 15, 15, 0.349);
    }

    .card:hover {
        box-shadow: 0px 0px 10px 1px rgba(85, 44, 44, 0.75);
    }
    .avatarimg:hover {
        box-shadow: 0px 0px 10px 1px rgba(85, 44, 44, 0.75);
    }


    display: flex;
    flex-direction: row;

    overflow: scroll;
    padding: 20px;

    .avatar {
        min-width: 300px;
        width: 30vw;
        h1 {
            text-align: center;
            font-size: 3.2rem;
            // font-size: max(3vw, 40px);
            margin-bottom: 13px;;
        }

        p {
            text-align: center;
            font-size: 1.4rem;
            // font-size: max(1vw, 14px);
        }


        display: flex;
        flex-direction: column;
        align-items: center;

        .tilt {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            .avatarimg {
                height: 100%;
                width: 85%;
                min-width: 200px;
                min-height: 200px;
                max-width: 100%;
                max-height: 100%;
                -o-object-fit: cover;
                   object-fit: cover;
                border-radius: 15px;
                margin-bottom: 20px;
            }
        }


    }

    .stats {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px 10px;
        height: 100%;



        .datadiv {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .playerstats {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                gap: 30px;

                .playerstatsitem {
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    padding: 15px;

                    h1 {
                        font-size: 2rem;
                    }

                    .statsheader {
                        height: 40%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .statsvalue {
                        height: 60%;
                        font-family: "Black ops One";
                        font-size: 4rem;
                        // font-size: max(3.2vw, 30px);
                    }
                }
            }

            .aircraft {
                width: 100%;
                height: 100%;
                min-height: fit-content;
                border-radius: 20px;
                margin: 20px;
                // overflow: scroll;

                .aircraft_img {
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    z-index: -1;
                    position: absolute;
                    object-fit: cover;
                }

                .aircraft_content {
                    border-radius: 20px;
                    // backdrop-filter: blur(5px);
                    // background-color: antiquewhite;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;

                    .aircraft_name {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        h1 {
                            font-size: 4rem;
                            // font-size: max(5vw, 50px);
                            margin-bottom: 2vh;
                        }

                        p {
                            font-size: 2rem;
                            // font-size: max(1.3vw, 20px);
                            // margin-bottom: 2vh;
                        }
                        // background-color: aqua;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                    }

                    .switch_aicraft_button {
                        top: 10px;
                        right: 10px;
                        position: absolute;
                    }

                    .aircraft_info {
                        display: flex;
                        flex-direction: column;;
                        height: 100%;
                        width: 100%;

                        .aircraft_stats {
                            margin-left: 50px;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            height: 100%;
                            width: 40%;

                            .statname {
                                display: flex;
                                position: relative;
                                margin: 2vh;
                                align-items: center;
                                font-size: 1.2rem;
                                .statvalue {
                                    position: absolute;
                                    // right: -100px;
                                    left: 10vw;
                                    font-size: 2.5rem;
                                    // font-size: max(3vw, 40px);
                                    font-family: 'Black Ops One';
                                }
                            }
                        }
                    }
                }
            }
        }

        .aircraft_selection {
            display: flex;
            justify-content: center;
            width: 100%;
        }

    }


}


