.nav-bg {
    position: fixed;
    width: 100vw;
    height: 90px;
    background-color: rgb(17, 17, 17, .3);
    box-shadow: 0px 0px 10px 10px rgb(17, 17, 17, .3);
    z-index: 9997;
    top: 0px;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

}

.nav-bg.scrolled {
    top: -100px;
}



.nav {
    z-index: 9998;
    left: 15vw;
    right: 15vw;
    height: 90px;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0px;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

    ul {
        position: relative;
        width: 100%;

        margin-top:30px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        li {

            margin: 3vw;

            min-width: 70px;
            min-height: 80px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .imgtext {

                    font-family: 'Black ops One';

                position: relative;
                top: 50px;
            }

            a {
                position: absolute;
                overflow: hidden;
                border-radius: 50%;

                height: 90px;
                width: 90px;

                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                box-shadow: 0px 0px 10px 10px rgb(17, 17, 17);

                transition: all 0.1s ease-in-out;
                padding: 4px 3px 0 3px;
                text-align: center;

                img {
                    max-height: 120%;
                    max-width: 120%;
                    position: relative;
                    bottom: 2px;
                    background-color: rgb(17, 17, 17);
                }
            }

            a:hover {
                transform: scale(1.1, 1.1);
            }

            a::after {
                content: '';
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                opacity: 0;
                border-radius: 5px;
                transition: opacity 0.1s ease-in-out;
            }
        }
    }
}

.nav.scrolled {
    top: -200px;
}

.logo {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 9998;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
}

.logo.scrolled {
    right: -300px;
}

.patreon {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 10;

    img {
        border-radius: 15px;
        width: 40%;
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    img:hover {
        width: 45%;
    }
}


.discord {
    position: fixed;
    bottom: 10px;
    right: -190px;
    // float:left;
    height:70px;
    width:250px;
    z-index: 100;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

}

.discord:hover {
    bottom: 3px;
    right: 3px;
    height:500px;
    width:350px;
}