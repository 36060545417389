.pilot {
    position: absolute;
    width: 100%;
    padding-top: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;
}

.searchfield {
    position: relative;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 25px;
    form {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    Button {
        margin: 20px;
        font-size: 1.1rem;
    }
}

.returnbutton {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    Button {
        padding: 15px;
    }
}