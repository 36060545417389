.leaderboard {
    position: absolute;
    top: 150px;
    left: 10vw;
    right: 10vw;
    display: flex;
    // align-items: center;
    flex-direction: column;
    padding-bottom: 100px;

    table {

        font-size: 1rem;
        width: 100%;
        text-align: left;
        border-spacing: 0 0em;
        border-radius: 15px;



        tbody {
            .trow {
                border-radius: 3px;
                transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1);
            }


        .tableitem_award {
            text-align: center;
        }

        .trow:hover {
            // font-size: 1.2rem;
            // backdrop-filter: blur(30px);
            background-color: rgba(199, 199, 199, 0.041);
            box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.75);
            cursor: pointer;
        }

        th {
            // text-align: center;
            // font-family: 'Roboto';
            font-weight: 700;
            user-select: none;
            cursor: pointer;
            padding: 30px;
        }

        td {

            padding: 30px;
            border-width: 1px;
            border-color: white;
            border-style: solid none none none;
            vertical-align: center;
            text-align: left;

                .rankdiv {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .rankdivshape {
                        content: "";
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        border: 5px solid;
                        border-radius: 10%;
                        transform: rotate(45deg);

                    }
                }

        }
    }
    }

    .css-19kzrtu {
        // No padding for MUI Tab selector
        padding: 0 !important;
    }

    .categoryBox {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-end;
        margin-bottom: 4px;
        width: 100%;

        .lastUpdated {
            margin-left: auto;
            margin-right: 100px;
            margin-bottom: 0;
            bottom: 0;
            p {
                color: rgb(236, 236, 236);
            }
        }
    }


    .searchfield {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
    }

    .css-jpln7h-MuiTabs-scroller {
        margin-left: 10px !important;
    }

}
