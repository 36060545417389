
// @import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
    font-family: "viking";
    src: url("./resources/viking.regular.ttf");
}

:root {
    color-scheme: only light;
    background-color: rgb(24, 24, 24);
}

* {
  margin: 0;
  padding: 0;

  scrollbar-width: none;
  box-sizing: border-box;
  color: white;
  -webkit-tap-highlight-color: transparent;

    font-family: 'Roboto';
    letter-spacing: .6px;

  body {
    width: 100vw;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(24, 24, 24);
  }

  *::-webkit-scrollbar {
    display: none;
}

}





