#background {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -10;
  min-width: 100vw;

  height: 100vh;
  img {
    // text-align: center;
    z-index: -9;
    min-height: 100%;
    // margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    // margin-right: auto;
    min-width: 102%;
  }

}

.bg-blur {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: rgb(0, 0, 0, 0);
    backdrop-filter: blur(5px);
}
