.container {
    position: absolute;
    display: flex;
    justify-content: center;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    // margin: 200px;


    .blur {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        background: rgb(20, 20, 20, .4);
    }

    .smoothboxshadow {
        box-shadow: 0px 0px 20px 6px rgba(19, 19, 19, 0.6);
    }
}
