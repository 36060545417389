.hangar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    h1, h2 {
        font-family: 'Black ops One';
    }

    ul {
        margin-left: 20px;
    }

    .vikings {
        text-shadow: rgb(240, 10, 10) 2px 0 10px;
        color: rgb(0, 0, 0);
        font-family: 'Viking';
        font-weight: 800;
    }


    .headertext {

        border-bottom: 2px solid rgb(255, 255, 255);
        width: 100%;
        text-align: center;
        font-size: 120%;
        margin-bottom: 40px;
        text-shadow: rgb(0, 0, 0) 1px 0 5px;

    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 90px;
        margin-top: 40px;
        max-width: 80vw;
        margin: 40px auto;

        .column {
          display: flex;
          align-items: center;
          gap: 40px;
          width: 100%;

          .image {
            /* Add styles for the image container here */
            width: 300px; /* Adjust the width as needed */
            /* Example: You can use a background image or an actual <img> element */
            img {
                width: 300px;
                border-radius: 25px;
                filter: drop-shadow(0px 0px 30px #000000);
            }
          }

          .text {
            flex: 1;

            h2 {
              font-size: 1.8rem;
              margin-bottom: 1rem;
            }

            p {
              font-size: 1.2rem;
              line-height: 1.6;
            }
          }
        }
      }


    top: 140px;
}


